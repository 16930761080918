<template>
	<b-container class="post-main-container" fluid>
		<h4>Post reported as {{ reason }}</h4>
		<p>This post has been deleted from your wall and reported to the CC-Team.<br />
			We will verify it and take appropriate actions to avoid infringements of the site policy.</p>
	</b-container>
</template>

<script>
export default {
	name: 'ReportedContent',
	props: {
		reason: {
			type: String,
			default: ''
		}
	}
}
</script>

<style scoped>
.post-main-container {
	background: white;
	margin-bottom: 15px;
	border-radius: 0 10px 10px 10px;
	padding: 15px;
}

.post-main-container h4 {
	font-size: 14px;
	font-weight: 600;
}

.post-main-container p {
	font-size: 11px;
}
</style>